import { Locales } from "@/types/common-types";

export const locales: { locale: Locales; textJa: string; textEn: string }[] = [
  {
    locale: "ja",
    textJa: "日本語",
    textEn: "Japanese",
  },
  {
    locale: "en",
    textJa: "英語",
    textEn: "English",
  },
  {
    locale: "zh-cn",
    textJa: "中国語(簡体字)",
    textEn: "Simplified Chinese characters ",
  },
  {
    locale: "zh-tw",
    textJa: "中国語(繁体字)",
    textEn: "Traditional Chinese characters",
  },
];

export const localesLanguageMap = {
  ja: "日本語",
  en: "英語",
  "zh-cn": "中国語（本土）",
  "zh-tw": "中国語（台湾・香港・マカオ）",
} as const;
