import { css } from "@emotion/react";
import React from "react";

import { Button, Dialog, Grid } from "@mui/material";

import { useLangSetting } from "@/custom-hooks/common/useLangSetting";
import { useLocale } from "@/custom-hooks/common/useLocale";
import { appColor } from "@/styles/app-colors";
import { appTypo } from "@/styles/app-typography";

import { LangSettingItem } from "../LangSettingItem";

import { locales } from "@/constants/locales";
import { SvgIconCancel } from "@/svgs";

type Props = {
  isOpenLangSettingDialog: boolean;
  onClickClose: () => void;
};

const LangSettingDialog: React.FC<Props> = (props) => {
  const { selectedLang, changeLang, redirectLocale } = useLangSetting({ onClose: props.onClickClose });
  const { translated } = useLocale();
  return (
    <Dialog css={rootStyled} onClose={props.onClickClose} open={props.isOpenLangSettingDialog}>
      <Grid css={dialogInnerStyled}>
        <Grid css={dialogHeadStyled}>
          <Grid css={textHeadStyled}>{translated.COMMON.LANGUAGE_IN_USE}</Grid>
          <Button css={iconCrossStyledContainer} onClick={props.onClickClose}>
            <SvgIconCancel />
          </Button>
        </Grid>
        <Grid px={2} pb={4}>
          {locales.map((localeObj, index) => {
            return (
              <LangSettingItem
                key={index}
                locale={localeObj.locale}
                textJa={localeObj.textJa}
                textEn={localeObj.textEn}
                selectedLang={selectedLang}
                changeLang={changeLang}
                redirectLocale={redirectLocale}
              />
            );
          })}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default React.memo(LangSettingDialog);

const rootStyled = css`
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: center;
  .MuiPaper-root {
    border-radius: 16px;
  }
`;

const dialogInnerStyled = css`
  display: flex;
  width: 393px;
  height: auto;
  flex-direction: column;
`;

const dialogHeadStyled = css`
  display: flex;
  width: 100%;
  height: 72px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 28px 16px;
  border-bottom: 1px solid ${appColor.Border.grayLight};
`;

const textHeadStyled = css`
  font-family: ${appTypo.font.kintoSans};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${appColor.Text.primary};
`;

const iconCrossStyledContainer = css`
  display: flex;
  width: 40px;
  // for override mui style
  min-width: 40px;
  height: 40px;
  // for override mui style
  min-height: 40px;
  align-items: center;
  justify-content: center;
  margin-left: 109px;
  padding: 0px;
  .MuiButtonBase-root {
  }
`;

const langItemContainer = css``;
