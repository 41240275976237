import { useRouter } from "next/router";
import { useState } from "react";

import { companyInfo, contactPath, mailorderLawPath, privacyPolicy, servicePolicyPath } from "@/constants/app-paths";

type Props = {
  textJapanese: string;
  textEnglish: string;
  textTraditionalChinese: string;
  textSimplifiedChinese: string;
};

export const useFooter = (props: Props) => {
  const router = useRouter();
  const [isOpenLangSettingDialog, setIsOpenLangSettingDialog] = useState(false);

  const onClickRedirectServicePolicy = () => {
    location.href = `/${router.locale}${servicePolicyPath}`;
  };

  const onClickRedirectContact = () => {
    location.href = `/${router.locale}${contactPath}`;
  };

  const onClickRedirectCompanyInfo = () => {
    location.href = `/${router.locale}${companyInfo}`;
  };

  const onClickRedirectMailorderlawPath = () => {
    location.href = `/${router.locale}${mailorderLawPath}`;
  };

  const onClickRedirectPrivacyPolicy = () => {
    location.href = `/${router.locale}${privacyPolicy}`;
  };

  const onClickOpenLangSetting = () => {
    setIsOpenLangSettingDialog(true);
  };

  const onClickCloseLangSetting = () => {
    setIsOpenLangSettingDialog(false);
  };

  const getCurrentLocale = () => {
    switch (router.locale) {
      case "ja":
        return `${props.textJapanese}(JP)`;
      case "en":
        return `${props.textEnglish}(EN)`;
      case "zh-tw":
        return `${props.textTraditionalChinese}(ZH_TW)`;
      case "zh-cn":
        return `${props.textSimplifiedChinese}(ZH_CN)`;
    }
  };

  return {
    isOpenLangSettingDialog,
    onClickRedirectServicePolicy,
    onClickRedirectContact,
    onClickRedirectPrivacyPolicy,
    onClickOpenLangSetting,
    onClickCloseLangSetting,
    onClickRedirectCompanyInfo,
    onClickRedirectMailorderlawPath,
    getCurrentLocale,
  };
};
