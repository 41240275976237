import { useRouter } from "next/router";
import { useCallback } from "react";

import { APIPaths } from "@/apis/api-config";
import { useApiConfig } from "@/custom-hooks/apis-common/use-api-config";
import { useApiConnector } from "@/custom-hooks/apis-common/use-api-connector";

import { loadingAtom } from "@/global-states/common-atoms";
import { useSetAtom } from "jotai";

export const useUserMutation = () => {
  const router = useRouter();
  const { throwApiError } = useApiConfig();
  const { appUserApi } = useApiConnector();

  const setLoading = useSetAtom(loadingAtom);
  const deps = [appUserApi, router.asPath];

  const patchUserMe = useCallback(
    async (requestBody: APIPaths["/app/user/me"]["patch"]["requestBody"]["content"]["application/json"]) => {
      try {
        setLoading(true);
        const result = await appUserApi.patchUserMe(requestBody);
        return result;
      } catch (e) {
        throwApiError(e);
      } finally {
        setLoading(false);
      }
    },
    deps,
  );

  const putUserMePassword = useCallback(
    async (requestBody: APIPaths["/app/user/me/password"]["put"]["requestBody"]["content"]["application/json"]) => {
      setLoading(true);
      try {
        return await appUserApi.putUserMePassword(requestBody);
      } catch (e) {
        throwApiError(e);
      } finally {
        setLoading(false);
      }
    },
    deps,
  );
  const postUserMeCreditCard = useCallback(
    async (
      requestBody: APIPaths["/app/user/me/credit-card/new"]["post"]["requestBody"]["content"]["application/json"],
    ) => {
      try {
        setLoading(true);
        const result = await appUserApi.postUserMeCreditCard(requestBody);
        return result;
      } catch (e) {
        throwApiError(e);
      } finally {
        setLoading(false);
      }
    },
    deps,
  );
  const deleteUserMeCreditCardById = useCallback(
    async (parameters: APIPaths["/app/user/me/credit-card/{id}"]["parameters"]["path"]) => {
      try {
        setLoading(true);
        const result = await appUserApi.deleteUserMeCreditCardById(parameters);
        return result;
      } catch (e) {
        throwApiError(e);
      } finally {
        setLoading(false);
      }
    },
    deps,
  );
  const putUserMeCreditCardById = useCallback(
    async (parameters: APIPaths["/app/user/me/credit-card/{id}/default"]["parameters"]["path"]) => {
      try {
        setLoading(true);
        const result = await appUserApi.putUserMeCreditCardById(parameters);
        return result;
      } catch (e) {
        throwApiError(e);
      } finally {
        setLoading(false);
      }
    },
    deps,
  );

  return { patchUserMe, putUserMePassword, postUserMeCreditCard, deleteUserMeCreditCardById, putUserMeCreditCardById };
};
