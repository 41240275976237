import { css } from "@emotion/react";

import { Grid, useMediaQuery, useTheme } from "@mui/material";

import { useFooter } from "@/custom-hooks/common/layout/useFooter";
import { useLocale } from "@/custom-hooks/common/useLocale";
import { appColor } from "@/styles/app-colors";
import { appTypo } from "@/styles/app-typography";
import { mqMinScreen } from "@/utils/mediaQuery";

import LangSettingDialog from "../../dialog/LangSettingDialog";
import { LangSettingDrawer } from "../../drawer/LangSettingDrawer";

import { SVGIconLogoColor, SvgIconLangSetting } from "@/svgs";

const Footer = () => {
  const { translated } = useLocale();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));
  const {
    isOpenLangSettingDialog,
    onClickRedirectServicePolicy,
    onClickRedirectContact,
    onClickRedirectCompanyInfo,
    onClickRedirectPrivacyPolicy,
    onClickOpenLangSetting,
    onClickCloseLangSetting,
    onClickRedirectMailorderlawPath,
    getCurrentLocale,
  } = useFooter({
    textJapanese: translated.COMMON.JAPANESE,
    textEnglish: translated.COMMON.ENGLISH,
    textTraditionalChinese: translated.COMMON.TRADITIONAL_CHINESE,
    textSimplifiedChinese: translated.COMMON.SIMPLIFIED_CHINESE,
  });
  return (
    <Grid css={rootStyled}>
      <Grid css={innerStyled}>
        <Grid css={topContainerStyled}>
          <SVGIconLogoColor />
          <Grid css={langSettingContainerStyled} onClick={onClickOpenLangSetting}>
            <SvgIconLangSetting />
            <Grid css={textLangStyled}>{getCurrentLocale()}</Grid>
          </Grid>
        </Grid>
        <Grid css={bottomContainerStyled}>
          <Grid css={textLinkStyled} onClick={onClickRedirectServicePolicy}>
            {translated.FOOTER.TERMS_OF_SERVICE}
          </Grid>
          <Grid css={textLinkStyled} onClick={onClickRedirectPrivacyPolicy}>
            {translated.FOOTER.PRIVACY_POLICY}
          </Grid>
          <Grid css={textLinkStyled} onClick={onClickRedirectMailorderlawPath}>
            {translated.FOOTER.MAILORDERLAW_DETAILS}
          </Grid>
          <Grid css={textLinkStyled} onClick={onClickRedirectCompanyInfo}>
            {translated.FOOTER.COMPANY_INFORMATION}
          </Grid>
          <Grid css={textLinkStyled} onClick={onClickRedirectContact}>
            {translated.PAGE_CONTACT.CONTACT}
          </Grid>
          <Grid css={textStyled}>{`© ${translated.FOOTER.ATAMI_CITY_TOURISM_ASSOCIATION}`}</Grid>
        </Grid>
      </Grid>
      {isMobile ? (
        <LangSettingDrawer
          open={isOpenLangSettingDialog}
          onClose={onClickCloseLangSetting}
          onOpen={onClickOpenLangSetting}
          disableSwipeToOpen={true}
          modalProps
        />
      ) : (
        <LangSettingDialog isOpenLangSettingDialog={isOpenLangSettingDialog} onClickClose={onClickCloseLangSetting} />
      )}
    </Grid>
  );
};

export default Footer;

const rootStyled = css`
  display: flex;
  width: 100vw;
  border-top: 1px solid ${appColor.Border.grayLight};
  background-color: ${appColor.Background.secondary};
  padding: 48px 16px 96px 16px;
  ${mqMinScreen("lg")} {
    padding: 48px 16px;
    flex-direction: column;
    align-items: center;
  }
`;

const innerStyled = css`
  display: flex;
  max-width: 1120px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const topContainerStyled = css`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;
`;

const langSettingContainerStyled = css`
  display: flex;
  width: auto;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
`;

const textLangStyled = css`
  font-family: ${appTypo.font.kintoSans};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-left: 8px;
`;

const bottomContainerStyled = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  ${mqMinScreen("lg")} {
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }
`;

const textLinkStyled = css`
  font-family: ${appTypo.font.kintoSans};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  white-space: pre-line;
  cursor: pointer;
`;

const textStyled = css`
  font-family: ${appTypo.font.kintoSans};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  white-space: pre-line;
`;
