export const GA_ID = process.env.GOOGLE_ANALYTICS_ID || "";

export const existsGaId = GA_ID !== "";

export const setUserId = (userId: string) => {
  if (!existsGaId) return;
  window.gtag("set", { user_id: userId });
};

export const resetUserId = () => {
  if (!existsGaId) return;
  window.gtag("set", { user_id: null });
};

export const pageview = (path: string, title: string) => {
  if (!existsGaId) return;
  window.gtag("config", GA_ID, {
    page_path: path,
    page_title: title,
  });
};

type eventProps = {
  action: string;
  category: string;
  label: string;
  value?: string | number;
};

export const event = ({ action, category, label, value = "" }: eventProps) => {
  if (!existsGaId) {
    return;
  }

  window.gtag("event", action, {
    event_category: category,
    event_label: JSON.stringify(label),
    value,
  });
};
