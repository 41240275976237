import { css } from "@emotion/react";

import { Grid, SwipeableDrawer, styled } from "@mui/material";

import { useLangSetting } from "@/custom-hooks/common/useLangSetting";
import { useLocale } from "@/custom-hooks/common/useLocale";
import { appColor } from "@/styles/app-colors";
import { appTypo } from "@/styles/app-typography";

import { LangSettingItem } from "../LangSettingItem";

import { locales } from "@/constants/locales";
import { SvgIconCancel } from "@/svgs";

const CustomSwiperbleDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
}));

type Props = {
  anchor?: "top" | "right" | "bottom" | "left";
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  swipeAreaWidth?: number;
  disableSwipeToOpen: boolean;
  modalProps: boolean;
};

export const LangSettingDrawer: React.FC<Props> = (props) => {
  const { translated } = useLocale();
  const { selectedLang, changeLang, redirectLocale } = useLangSetting({ onClose: props.onClose });

  return (
    <CustomSwiperbleDrawer
      anchor={props.anchor || "bottom"}
      open={props.open}
      onClose={props.onClose}
      onOpen={props.onOpen}
      swipeAreaWidth={props.swipeAreaWidth || 56}
      disableSwipeToOpen={props.disableSwipeToOpen || false}
      ModalProps={{
        keepMounted: props.modalProps || true,
      }}
      style={{ borderTopRightRadius: "16px", borderTopLeftRadius: "16px" }}
    >
      <Grid css={headerStyled}>
        <Grid css={headerTitleStyled}>{translated.COMMON.LANGUAGE_IN_USE}</Grid>
        <Grid css={cancelIconBoxStyled} onClick={props.onClose}>
          <SvgIconCancel />
        </Grid>
      </Grid>
      <Grid px={2} pb={4}>
        {locales.map((localeObj, index) => {
          return (
            <LangSettingItem
              key={index}
              locale={localeObj.locale}
              textJa={localeObj.textJa}
              textEn={localeObj.textEn}
              selectedLang={selectedLang}
              changeLang={changeLang}
              redirectLocale={redirectLocale}
            />
          );
        })}
      </Grid>
    </CustomSwiperbleDrawer>
  );
};

const headerStyled = css`
  position: relative;
  display: grid;
  width: 100%;
  height: 72px;
  place-items: center;
  border-bottom: 1px solid ${appColor.Border.grayLight};
`;

const headerTitleStyled = css`
  font-family: ${appTypo.font.kintoSans};
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 16px;
  color: ${appColor.Text.primary};
`;

const cancelIconBoxStyled = css`
  position: absolute;
  top: 16px;
  right: 16px;
  display: grid;
  width: 40px;
  height: 40px;
  place-items: center;
`;
