import { useRouter } from "next/router";
import { useState } from "react";

import { Locales } from "@/types/common-types";

type Props = {
  onClose: () => void;
};

export const useLangSetting = (props: Props) => {
  const router = useRouter();
  const { locale } = router;
  const [selectedLang, setSelectedLang] = useState<Locales>(locale as Locales);

  const changeLang = (locale: Locales) => setSelectedLang(locale);

  const redirectLocale = async (locale: Locales) => {
    props.onClose();

    await router.push(router.asPath, router.asPath, { locale });
  };

  return {
    selectedLang,
    changeLang,
    redirectLocale,
  };
};
