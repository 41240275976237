import { css } from "@emotion/react";

import { Grid } from "@mui/material";

import { useUserMutation } from "@/custom-hooks/apis-mutation/use-user-mutation";
import { appColor } from "@/styles/app-colors";
import { appTypo } from "@/styles/app-typography";

import { LocalForageKeys } from "@/constants/local-forage-keys";
import { SVGIconCheck } from "@/svgs";
import { Locales } from "@/types/common-types";
import localforage from "localforage";

type Props = {
  locale: Locales;
  textJa: string;
  textEn: string;
  selectedLang: string;
  changeLang: (locale: Locales) => void;
  redirectLocale: (locale: Locales) => void;
};

export const LangSettingItem: React.FC<Props> = (props) => {
  const { patchUserMe } = useUserMutation();

  const onClickSelect = async () => {
    props.changeLang(props.locale);

    props.redirectLocale(props.locale);
    // ログイン状態の場合、ユーザーの言語設定を更新する
    const accessToken = await localforage.getItem<string>(LocalForageKeys.auth.AccessToken);
    if (accessToken !== undefined && accessToken !== null) {
      await patchUserMe({ language: props.locale });
    }
  };

  return (
    <Grid css={rootStyled} onClick={onClickSelect}>
      {props.selectedLang == "en" ? (
        <Grid>
          <Grid css={textJa}>{props.textEn}</Grid>
        </Grid>
      ) : (
        <Grid>
          <Grid css={textJa}>{props.textJa}</Grid>
          <Grid css={textEn}>{props.textEn}</Grid>
        </Grid>
      )}
      <Grid css={iconCheckBox}>{props.selectedLang === props.locale && <SVGIconCheck />}</Grid>
    </Grid>
  );
};

const rootStyled = css`
  display: flex;
  width: 100%;
  height: 64px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 0px;
  border-bottom: 1px solid ${appColor.Border.grayUltraLight};
`;

const textJa = css`
  font-family: ${appTypo.font.kintoSans};
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  color: ${appColor.Text.primary};
`;

const textEn = css`
  font-family: ${appTypo.font.kintoSans};
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: ${appColor.Text.primary};
  margin-top: 8px;
`;

const iconCheckBox = css`
  display: grid;
  width: 24px;
  height: 24px;
  place-items: center;
`;
