import Head from "next/head";

import { useApiConnector } from "@/custom-hooks/apis-common/use-api-connector";
import { GA_ID, existsGaId } from "@/utils/gtag";

import { authTokenInfoAtom } from "@/global-states/common-atoms";
import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";

export const GoogleAnalytics: React.FC = (props) => {
  const { appUserApi } = useApiConnector();
  const authTokenInfo = useAtomValue(authTokenInfoAtom);

  const { data: user } = useQuery({
    queryKey: ["appUserApi.getUserMe"],
    queryFn: () => appUserApi.getUserMe(),
    enabled: !!authTokenInfo?.accessToken,
  });

  return (
    <>
      {existsGaId && (
        <Head>
          <script defer src={`https://www.googletagmanager.com/gtag/js?id=${GA_ID}`} />
          <script
            id="ga"
            defer
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${GA_ID}');
            `,
            }}
          />
          {authTokenInfo?.accessToken ? (
            <script
              id="ga-user"
              defer
              dangerouslySetInnerHTML={{
                __html: `
                gtag('set', 'user_id', '${user?.object.id}');
              `,
              }}
            />
          ) : (
            <script
              id="ga-user"
              defer
              dangerouslySetInnerHTML={{
                __html: `
                gtag('set', 'user_id', null);
              `,
              }}
            />
          )}
        </Head>
      )}
    </>
  );
};
